<template>
  <el-container>
    <el-aside :width="isCollapse ? '3%' : '20%'" :style="isCollapse ? 'transition: width .5s;' : ''">
      <h1 :style="isCollapse ? 'font-size: 0px;transition: font-size .3s;' : 'font-size:20px;transition: font-size .3s;'">考试列表</h1>
      <div :width="isCollapse ? '0px' : '100%'" :style="isCollapse ? 'transition: width .5s;' : ''" class="aside-input">
        <el-input
          v-model="searchInfo.examName"
          placeholder="输入考试名称"
          prefix-icon="el-icon-search"
          v-if="!isCollapse"
          class="myinput"
          clearable
          @input="getExamInfoController()"
        ></el-input>
      </div>
      
      <!-- 关闭展开二级菜单的按钮 -->
      <i
        class="el-icon-menu closeBtn1"
        @click="collapseStatus()"
      ></i>

      <el-menu
        :default-active="activeMenu"
        class="el-menu-vertical-demo main-menu"
        :collapse="isCollapse"
      >
        <!-- 列表区域 -->
        <el-menu-item
          :index="index.toString()"
          v-for="(item, index) in examInfoLists"
          :key="index"
          @click="chooseChecked(item.uuid, item.name, index.toString())"
        >{{item.name }}
        </el-menu-item>
      </el-menu>
    </el-aside>

    <!-- 展开二级菜单的按钮 -->
    <!-- <i
      class="el-icon-menu closeBtn2"
      v-if="isCollapse"
      @click="collapseStatus()"
    ></i> -->

    <el-main>
      <div class="btndiv">
        <el-button type="primary" icon="el-icon-plus" class="hbtn" @click="openAddStaffDialog"
          >单个创建</el-button
        >
        <el-button type="primary" icon="el-icon-plus" class="hbtn" @click="showImportDialog"
          >批量导入</el-button
        >
        <el-button type="primary" icon="el-icon-download" class="hbtn" @click="exportExcel()"
          >导出全量表</el-button
        >
        <el-button type="danger" icon="el-icon-close" :disabled="isDisable" class="hbtn" @click="deleteStaffBatch()"
          >批量删除</el-button
        >
      </div>
      <div></div>
      <el-card class="box-card">
        <!-- 头部按钮 -->
        <div slot="header" class="clearfix">
          <!-- 头部标题与form表单 -->
          <el-row>
            <!-- span属性指的是占据多少列 -->
            <el-col>
              <span>监考人员列表</span>
              <el-input
                v-model="queryInfo.teacherName"
                placeholder="输入监考人员姓名"
                prefix-icon="el-icon-search"
                clearable
                @input="getStaffPageLists()"
              ></el-input>
            </el-col>
          </el-row>
        </div>

        <div class="text item">
          <!-- 主体table表格 -->
          <el-table
            :data="staffLists"
            stripe
            :max-height="tableHeight"
            tooltip-effect="light"
            @selection-change="selectionChange"
          >
            <el-table-column
              align="center"
              type="selection"
              label="全选"
              width="55"
              fixed
            ></el-table-column>
            <el-table-column
              align="center"
              prop="teacherName"
              label="姓名"
              min-width="180"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="phone"
              label="手机号"
              min-width="180"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="realPassword"
              label="登录密码"
              min-width="180"
            ></el-table-column>
            <el-table-column align="center" label="操作" min-width="180" fixed="right">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  style="background-color:#8B2D87;"
                  @click="openEditStaffDialog(scope.row)"
                  >修改</el-button
                >
                <el-button
                  size="mini"
                  style="background-color:#DA8309;"
                  @click="deleteStaff(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <!-- 分页器 -->
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryInfo.pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            :page-count="totalPage"
          >
          </el-pagination>
        </div>
      </el-card>
    </el-main>

    <!-- dialog 弹框 -->
    <StaffDialog
      :dialogVisibleFu="dialogVisibleFu"
      :dialogText="dialogText"
      :examIds="queryInfo.examId"
      :dialogForm="dialogForm"
      @closeDialog="closeDialog"
    />
    <!--批量导入弹框-->
    <ImportExcelDialog
      :dialogVisible="importDialogType"
      @closeDialog="closeDialog"
      :examId="queryInfo.examId"
      :excelType="excelType">
    </ImportExcelDialog>
  </el-container>
</template>

<script>
import {
  getExamData,
  getStaffAllData,
  getStaffPageData,
  teacherIsGroup,
  deleteTeacher,
  deleteTeacherBatch,
  getExamIdStaffData
} from "r/admin/staff";
import StaffDialog from "c/admin/staff/StaffDialog";
import ConfirmDialog from "c/admin/staff/ConfirmDialog";
import ImportExcelDialog from "c/admin/examinee/ImportExcelDialog";
import { export2Excel } from "common/exportExcel.js";
import { getDate } from "common/utils";

export default {
  name: "Lists",
  data() {
    return {
      searchInfo: {
        examName: "",
        status: "",
        pageNum: 1,
        pageSize: 100,
      },
      queryInfo: {
        teacherName: "",
        examId: "",
        pageNum: 1,
        pageSize: 10,
      },
      total: 0, // 数据总条数
      totalPage: 1, // 总页数
      staffLists: [], // 监考人员信息列表
      examInfoLists: [], // 考试管理数据列表
      examName:'',
      // 弹框文字
      dialogText: {
        dialogTitle: "",
        btnText: "",
      },
      dialogVisibleFu: false, // 控制dialog弹框显示与隐藏
      dialogForm: {}, // 表单数据对象
      dialogFormRules: {}, // 表单验证规则
      selectData: [], //表格中选中项的数据集合
      selectCount: 0, // 表格中选中项的数量
      ids: [], // 选中的列的id数组集合
      isCollapse: false, // 是否水平折叠
      importDialogType: false,//批量导入弹框状态
      excelType: '2',//导入的Excel：1（学生），2（监控人员）
      isDisable: false, // 提交按钮是否可点击（防重复点击）
      activeMenu: '', // 当前激活的二级分类
      tableHeight: '',
    };
  },
  created() {
    //页面创建时执行一次getHeight进行赋值，顺道绑定resize事件
    window.addEventListener("resize", this.getHeight);
    this.getHeight();
    
    this.activeMenu = window.sessionStorage.getItem('admin-activeMenu')
    const token = window.localStorage.getItem('index-token')
    // console.log(token,"token是否过期")
    // 调用获取考试管理所有考试数据列表的方法
    this.getExamInfoController();
  },
  methods: {
    // 保存当前激活的分类
    activeHandle (active) {
      window.sessionStorage.setItem('admin-activeMenu', active)
      this.activeMenu = active
    },

    //定义方法，获取高度减去头尾
    getHeight() {
      this.tableHeight = window.innerHeight - 341 + "px";
    },

    // 获取考试管理所有考试数据列表
    async getExamInfoController() {
      const { data: res } = await getExamData(this.searchInfo);
      // console.log(res);
      if (res.code !== 200) return this.$message.error(res.message);
      this.examInfoLists = res.data.records;
      // 默认考试列表第一项 uuid
      this.queryInfo.examId = res.data.records[this.activeMenu].uuid || res.data.records[0].uuid;
      this.examName = res.data.records[this.activeMenu].name || res.data.records[0].name;

      // 调用根据姓名获取监考人员（input搜索）的方法
      this.getStaffPageLists();
    },

    // 根据姓名获取监考人员（input搜索）
    async getStaffPageLists() {
      const { data: res } = await getStaffPageData(this.queryInfo);

      if (res.code !== 200) return this.$message.error(res.message);

      this.staffLists = res.data.list;
      this.total = res.data.total;
      this.totalPage = res.data.totalPage;
    },

    // 控制每页显示几条数据
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getStaffPageLists();
    },
    // 控制显示第几页
    handleCurrentChange(newNum) {
      this.queryInfo.pageNum = newNum;
      this.getStaffPageLists();
    },

    // 选择二级菜单，更新表格
    chooseChecked(examId, examName, index) {
      this.queryInfo.examId = examId;
      this.examName = examName;

      this.activeHandle(index)

      this.getStaffPageLists();
    },

    // 关闭弹框
    closeDialog(value) {
      this.dialogVisibleFu = value
      this.importDialogType = value
      // 刷新表格
      this.getStaffPageLists();
    },

    // 打开修改监考人员弹框
    openEditStaffDialog(data) {
      // console.log("aaa");
      // console.log(data);
      this.dialogForm = data;
      this.dialogVisibleFu = true;
      this.dialogText.dialogTitle = "单个修改";
      this.dialogText.btnText = "修 改";
    },
    // 打开单个添加监考人员弹框
    openAddStaffDialog() {
      this.dialogForm = {
        createTime: new Date(),
        examId: this.queryInfo.examId,
        id: 0,
        realPassword: "",
        phone: "",
        schoolId: 0,
        schoolName: "",
        status: 0,
        teacherName: "",
      };
      this.dialogVisibleFu = true;
      this.dialogText.dialogTitle = "单个新建";
      this.dialogText.btnText = "创 建";
    },

    //打开批量导入弹框
    showImportDialog(){
      this.importDialogType = true
    },
      
    // 单个删除操作
    async deleteStaff(data) {
        // console.log(data)
      // 验证监考人员是否在监控
      const { data: ress } = await teacherIsGroup({
        examId: data.examUuid,
        teacherIds: data.teacherId,
      })
      if(ress.code !== 200) return this.$message.error(ress.message)
      // const confirmResult = await this.$confirm( `确认删除监考人员：${data.teacherName} ？`, "提示",
      const confirmResult = await this.$confirm( `${ress.data}`, "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false,
        }
      ).catch((err) => err);

      if (confirmResult !== "confirm")
        return this.$message.info("您取消了删除");

      // 发送请求
      const { data: res } = await deleteTeacher(data.teacherId, { examId: data.examUuid });
      if (res.code !== 200) return this.$message.error(res.message);
      this.$message.success(res.message);

      // 刷新表格
      this.getStaffPageLists();
    },

    // 选中状态改变
    selectionChange(val) {
      // val 指选中的列的数组集合
      // 清空ids数据
      // console.log(val)
      this.selectData = val;
      this.ids = [];
      this.selectCount = val.length;
      val.forEach((item) => {
        // console.log(item.teacherId);
        this.ids.push(item.teacherId);
      });
    },

    // 批量删除操作
    async deleteStaffBatch() {
       
      this.isDisable = true
      setTimeout(() => {
        this.isDisable = false
      }, 1000)

      if (this.selectCount <= 0)
        return this.$message.info("您还未选择需要删除的监考人员");

      // 验证监考人员是否在监控
      const { data: ress } = await teacherIsGroup({
        examId: this.queryInfo.examId,
        teacherIds: this.ids.join(",")
      })
      // console.log(ress)
      if(ress.code !== 200) return this.$message.error(ress.message)
      // const confirmResult = await this.$confirm( `确认删除所选 ${this.selectCount} 条数据？`, "提示",
      const confirmResult = await this.$confirm( `${ress.data}`, "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false,
        }
      ).catch((err) => err);

      if (confirmResult !== "confirm")
        return this.$message.info("您取消了删除");

      // 发送请求
      const { data: res } = await deleteTeacherBatch({
        ids: this.ids.join(","),
        examId: this.queryInfo.examId
      });
      if (res.code !== 200) return this.$message.error(res.message);
      this.$message.success(res.message);

      // 刷新表格
      this.getStaffPageLists();
    },

    // 控制是否水平折叠收起菜单
    collapseStatus() {
      this.isCollapse = !this.isCollapse;
    },

    // 导出全量表
    async exportExcel() {
      const tHeader = ["姓名", "手机号", "登录密码"];
      const filterVal = ["teacherName", "phone", "realPassword"];
      let list = []; 
      let date = getDate("yyyy-MM-dd_hh:mm:ss", new Date());
      let excelName = this.examName + "_监考人员信息全量表" + date;
      // console.log(this.selectData);
      if (this.selectData.length > 0) {
        list = this.selectData;
      } else {
        const { data: res } = await getExamIdStaffData({examId: this.queryInfo.examId})
        // console.log(res)
        if (res.code !== 200) return this.$message.error(res.message);
        list = res.data;
      }
      if (list.length === 0) {
        return this.$message.info("暂无监考人员信息");
      }
      // 调用导出excel表格公共方法
      export2Excel(tHeader, filterVal, list, excelName);
    },

    // // 获取全部监考人员
    // ,async getStaffALLLists() {
    //   const { data: res } = await getStaffAllData();
    //   if (res.code !== 200) return this.$message.error(res.message);

    // //   res.data.forEach(item => {
    // //     //   item.realPassword=decryptDes(item.realPassword,this.$enkey)
    // //   })
    //   this.staffLists = res.data;
    //   this.total = res.data.length;
    //   if(this.total>this.pageSize){
    //       this.totalPage = Number(this.total/this.pageSize)+1
    //   }else{
    //       this.totalPage = 1
    //   }

    // },
  },
  components: {
    StaffDialog,
    ImportExcelDialog,//批量导入弹框
  },
};
</script>

<style scoped lang="scss">
@import "a/scss/menuitem";
@import "a/scss/contentheader";
@import "a/scss/table";
@import "a/scss/page";
</style>

<template>
  <!-- dialog弹框 -->
  <el-dialog
    :visible.sync="dialogVisible"
    center
    @close="resetForm"
    :before-close="cancelBtn"
    :close-on-click-modal="false"
  >
    <span>aaaaaaaa</span>

    <!-- 底部按钮区域 -->
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="cancelBtn">取 消</el-button>
      <el-button size="small" type="primary" @click="submitForm"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { addTeacher, updateTeacher } from "r/admin/staff";
export default {
  name: "StaffDialog",
  props: {
    dialogVisibleFu: Boolean,
    dialogText: Object,
    examIds: String,
    dialogForm: Object,
  },
  data() {
    return {
      dialogVisible: this.dialogVisibleFu, // 控制dialog弹框显示与隐藏
      isDisable: false, // 提交按钮是否可点击（防重复点击）
    };
  },
  methods: {
    // 取消,关闭弹框，修改父组件的值
    cancelBtn() {
      this.dialogVisible = false;
      this.$emit("closeDialog", this.dialogVisible);
    },

    // 确定,提交表单
    async submitForm() {
      this.isDisable = true;
      setTimeout(() => {
        this.isDisable = false;
      }, 1000);
      
      const { data: res } = await updateTeacher(this.dialogForm);
      // console.log(res);
      if (res.code !== 200) return this.$message.error(res.message);
      this.$message.success(res.message);
    },
  },
  watch: {
    dialogVisibleFu() {
      this.dialogVisible = this.dialogVisibleFu;
    },
  },
};
</script>

<template>
  <!-- dialog弹框 -->
  <el-dialog
    :title="this.dialogText.dialogTitle"
    :visible.sync="dialogVisible"
    center
    @close="resetForm"
    :before-close="cancelBtn"
    :close-on-click-modal="false"
  >
    <!-- 表单区域 -->
    <el-form
      :model="dialogForm"
      :rules="dialogFormRules"
      ref="dialogFormRef"
      label-width="100px"
      class="demo-ruleForm"
      style="padding: 30px 30px 0 30px"
    >
      <el-form-item label="监考姓名：" prop="teacherName">
        <el-input
          v-model="dialogForm.teacherName"
          placeholder="请输入监考人员姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号码：" prop="phone">
        <el-input
          v-model="dialogForm.phone"
          placeholder="请输入监考人员手机号码"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="登录密码："
        prop="realPassword"
        v-if="dialogText.btnText === '修 改'"
      >
        <el-input
          v-model="dialogForm.realPassword"
          placeholder="请输入登录密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码：" v-if="dialogText.btnText === '修 改'">
        <el-input v-model="confirmPwd" placeholder="请确认登录密码"></el-input>
      </el-form-item>
    </el-form>

    <!-- 确认弹框 -->
    <el-dialog
      title="提示"
      :visible.sync="confirmDialog"
      center
      append-to-body
      width="320px"
      custom-class="mini"
      :close-on-click-modal="false"
    >
      <span>{{ content }}</span>

      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="confirmDialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="confirmForm"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <!-- 底部按钮区域 -->
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="cancelBtn">取 消</el-button>
      <el-button
        size="small"
        type="primary"
        @click="submitForm"
        :disabled="isDisable"
        >{{ this.dialogText.btnText }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { addTeacher, updateTeacher, getTeacher } from "r/admin/staff";

export default {
  name: "StaffDialog",
  props: {
    dialogVisibleFu: Boolean,
    dialogText: Object,
    examIds: String,
    dialogForm: Object,
  },
  data() {
    return {
      dialogVisible: this.dialogVisibleFu, // 控制dialog弹框显示与隐藏
      examId: this.examIds,
      dialogFormRules: {
        teacherName: [
          { required: true, message: "请输入监考人员姓名", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
          {
            pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: "请输入正确的手机号码",
          },
        ],
        realPassword: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
          { min: 3, message: "长度最小为3", trigger: "blur" },
        ],
      }, // 表单验证规则
      confirmPwd: "", // 确认密码
      isDisable: false, // 提交按钮是否可点击（防重复点击）
      confirmDialog: false, // 控制确认dialog弹框显示与隐藏
      content: "", // 确认弹框文字
    };
  },
  methods: {
    // 确认弹框确认修改
    async confirmForm() {
      // console.log(this.examId, this.dialogForm);
      const { data: res } = await updateTeacher(this.examId, this.dialogForm);
      // console.log(res);
      if (res.code !== 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.confirmDialog = false;
      this.content = ''
      this.cancelBtn();
    },
    // 重置表单
    resetForm() {
      this.$refs.dialogFormRef.resetFields();
    },

    // 取消,关闭弹框，修改父组件的值
    cancelBtn() {
      this.confirmPwd = "";
      this.dialogVisible = false;
      this.isDisable = false;
      this.resetForm();
      this.$emit("closeDialog", this.dialogVisible);
    },

    // 确定,提交表单
    async submitForm() {
      this.isDisable = true;
      setTimeout(() => {
        this.isDisable = false;
      }, 1000);

      this.$refs.dialogFormRef.validate(async (valid) => {
        if (!valid) return;

        if (this.dialogText.btnText === "修 改") {
          // console.log("修 改");

          if (this.confirmPwd !== this.dialogForm.realPassword)
            return this.$message.info("两次密码不一致");

          const { data: res } = await getTeacher(this.dialogForm);
          // console.log(res);
          if (res.code == 200) {
            this.content = "是否确认修改？";
          } else if (res.code == 500) {
            this.content = res.message + "，是否确认修改？";
          } else {
            return this.$message.error(res.message);
          }
          this.confirmDialog = true;

        } else if (this.dialogText.btnText === "创 建") {
          // console.log("创 建");
          const { data: res } = await addTeacher(this.dialogForm);
          if (res.code !== 200) return this.$message.error(res.message);
          this.$message.success(res.message);

          this.cancelBtn();
        }
      });
    },
  },
  watch: {
    dialogVisibleFu() {
      this.dialogVisible = this.dialogVisibleFu;
      this.examId = this.examIds;
    },
  },
};
</script>

<style scoped lang="scss">
@import "a/scss/dialog";
</style>
